/**
 * @desc Template para cada evento
 *
 * TODO Adicionar Schema.org ao evento
 * TODO Adicionar navegação entre eventos
 *  - Fazer query dos próximos e adicionar ao pageContext
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import parse from 'html-react-parser';
import { Event, RoomOutlined } from '@material-ui/icons';
import ImageMasonry from 'react-image-masonry';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import PageHeader from '../ui/PageHeader';
import IconInfo from '../ui/IconInfo';
import SEO from '../components/layout/seo';

const EventoTemplate = ({ pageContext }) => (
  <>
    <SEO title={pageContext.title} />
    <Container maxWidth="md">
      <PageHeader title={pageContext.title} />

      <IconInfo
        icon={<Event />}
        text={new Intl.DateTimeFormat('pt-br', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(pageContext.datetime))}
      />

      <IconInfo icon={<RoomOutlined />} text={pageContext.address} />

      <Box>{parse(pageContext.content)}</Box>

      <Box my={6} mb={20}>
        <SimpleReactLightbox>
          <SRLWrapper options={{ caption: { showCaption: false } }}>
            <ImageMasonry
              imageUrls={pageContext.images.map((e) => e.url)}
              numCols={2}
              animate={false}
              scrollable={false}
            />
          </SRLWrapper>
        </SimpleReactLightbox>
      </Box>
    </Container>
  </>
);

EventoTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default EventoTemplate;
